
/* ------------ DRUPAL 8 ADMIN TOOLS -------------- */

#block-vonarx-local-tasks {
    background-color: rgba(0, 74, 127 , 0.75);
    position: fixed;
    bottom: 0;
    width: 90%;
    text-align: center;
    z-index: 10;


    & > ul {
        list-style: none;
        margin: 2.5em 0 0.5em;
        padding: 0;
        display: inline-block;
        li {
            display: inline-block;
            margin: 0 0.5em;

            a {
                color: @white;
            }
        }
    }
}

#user-login-form {
    &:extend(.wrapper);
    &:extend(.clearfix);
    padding: 20em 3em 15em;

    .description {
        font-size: 0.75em;
        margin: 0 0 1em 6rem;
    }
    label {
        display: inline-block;
        //width: 6em;
    }
}

.toolbar-tab:hover .toolbar-tray {
    display: block;
}
.toolbar .toolbar-tray {
    position: absolute;
}

/* ------------ NAV -------------- */

#loader {
    position: fixed;
    z-index: 20;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: #fff;
    color: @blue;
    pointer-events: none;
    transition: opacity 1s, visibility 0s 1s;

    .page-loaded &,
    .no-js & {
        opacity: 0;
        visibility: hidden;
    }
    p {
        position: absolute;
        top: 50%; left: 0;
        width: 100%;
        text-align: center;
        margin-top: -2em;
        line-height: 3;

        @keyframes loader {
            0%   {opacity: 1;}
            50%  {opacity: 0.2;}
            100% {opacity: 1;}
        }

        .logo {
            display: inline-block;
            width: 142px; height: 120px;
            background: url(../img/logo-vonarx.svg) no-repeat;
            animation-name: loader;
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }
        .text:before {
            content: "Chargement...";
        }
    }
}


.header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
}

/* Menu Mobile */

.ic {
    position: fixed; 
    cursor: pointer;
    display: inline-block;
    right: 25px;
    width: 32px;
    height: 24px;
    text-align: center;
    top:0px;
    outline: none;
}

.ic.close { 
    opacity: 0; 
    font-size: 0px; 
    font-weight: 300; 
    color: @blue;
    top:8px;
    height:40px;
    display: block;
    outline: none;
}

/* Menu Icons for Devices*/
.ic.menu { top:25px; z-index : 20; }

.ic.menu .line { 
    height: 3px; 
    width: 100%; 
    display: block; 
    margin-bottom: 6px; 
    background-color: @blue;
}
.ic.menu .line-last-child { margin-bottom: 0px;  }

@media only screen and (max-width:@Md) {

    #logo-mobile {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: auto;
        border-bottom: 1px solid @border-gray;
        width: 100%;
        padding: .5em 5%;
        background: @white;
        box-shadow: 0.5px 1px 21px 0 rgba(0, 0, 0, 0.15);
        z-index: 10;

        img {
            max-width: 4em;
        }
    }

    .absolute-img {
        display: none!important;
    }

    .ic.menu { display: block; }
    .ic.menu .line {
        transition: all 0.4s ease 0s;
        transform: rotate(0deg);
        transform-origin: center center;
    }
    .ic.menu.active .line { background-color: @blue !important; }

    .ic.menu.active .line:nth-child(1) { 
        transform: rotate(45deg); 
    }

    .ic.menu.active .line:nth-child(2) { 
        transform: rotate(-45deg); 
        margin-top: -10px;
    }

    .ic.menu.active .line:nth-child(3) {
        transform: translateY(15px);
        opacity: 0;
    }

    .ic.menu.active{ outline: none; }
    .ic.menu.active ~ .ic.close { opacity: 1; z-index : 21;  outline: none;  }

    /*
    
    .ic.menu.active ~ .ic.close { opacity: 1.0; z-index : 21;  }
    .ic.close.active { opacity: 0; }
    */
    .ic.menu.active { opacity: 1;  }


    nav { background-color: transparent; }

    /* Main Menu for Handheld Devices  */
    /*ul.menu {*/
    header.header {
        z-index:10; 
        padding: 50px 0;
        position: fixed;
        right: 0px;
        top: 0px;
        width: 0px;
        background-color:rgba(255,255,255,1);
        height: 100%;
        overflow: auto;
        /*CSS animation applied : Slide from Right*/
        transition-property: background, width;
        transition-duration: 0.6s;
    }

    .ic.menu.active ~ header.header { width: 300px; background-color:@contact-gray; }

    /*ul.menu > */
    header.header > * { 
        transition-property: opacity;
        transition-duration: 0.4s;
        opacity: 0;
    }

    #preheader-nav {
        ul {
            &.menu {
                display: none;
            }
            &.links {
                margin-top: 1em;
                position: absolute;
                top: 0;
                left: auto;

                li {
                    float: left;
                    padding: 0 .5em;
                    a {
                        font-size: 0.875em;
                        color: @light-gray;
                    }
                }
            }
        }
    }

    .ic.menu.active ~  header.header> * {opacity: 1;}

    ul.menu > li > a:after {display: none;}
    ul.menu > li:first-child { border-radius: 0px; }
    ul.menu > li {
        display: block;
        border-bottom: 1px solid @border-gray;
        padding: 1em 0 0 1em;

        &.logo {
            padding-top: 0;
            border: none;
            .wrapper-logo {
                display: none;

                @media @screenMd {
                    display: block;
                    max-width: 5em;
                    margin-top: -2em;
                }
            }
        }

        .btn-close {
            display: none;
        }
        ul {
            & > li {

                a {
                    font-weight: bold;
                    line-height: 1.5;
                }
                ul {
                    & > li {
                        a {
                            font-weight: normal;
                        }
                    }
                }
            }

            &.list-services {
                padding: 0 0 1em;
                & > li {
                    margin: 0;
                    padding: 0;
                    line-height: 1.2;

                    & > a {
                        font-size: 1em;
                        font-weight: bold;
                        color: @blue;

                        &:hover {
                            color:@light-blue;
                        }
                    }
                }
            }

            &.social {
                padding: 0;
                & > li {
                    display: inline-block;
                    padding: 1em 1em 0 0;
                }
            }
        }

    }

    ul.menu > li > a { font-weight: 600; }

    ul.menu > li ul.sub-menu-lists > li a { color: #eee; font-size: 14px; }
    .sub-menu-head { font-size: 16px;}
    ul.menu > li:hover { background-color: transparent;  }
    ul.menu > li:hover > a {color: #fff; text-decoration: none; font-weight: 600;}
    .ic.menu.active ~ ul.menu > li > div.sub-menu-block {
        border-left: 0px solid #ccc;
        border-right: 0px solid #ccc;
        border-bottom: 0px solid #ccc;
        position: relative;
        visibility: visible;
        opacity: 1.0;
    }

    .sub-menu-block { padding: 0 2em 1em; }
}


@media @screenMd {
    .ic.menu { display: none; }
    #logo-mobile { display: none; }

    header.header {
        position: relative;
        background: @white;
        z-index: 5;
    }

    #nav-collapser {
        display: none;
    }

    #preheader-nav {
        background: @dark-gray;
        ul {
            display: inline-block;
            padding: 0; margin: 0;
            list-style: none;

            li {
                display: inline-block;
                padding: 1em .5em;
                a {
                    font-size: 0.875em;
                    text-transform: uppercase;
                    display: block;
                    color: @white;


                    &.active, &:hover {
                        color: @light-blue;
                    }

                    &.is-active {
                        background: @black;
                    }
                }
            }

            &.links {
                float: right;

                li {
                    a {
                        font-size: 0.625em;
                        color: @light-gray;
                    }
                }
            }

        }
    }

    #main-nav {
        display: block;
        position: relative;
        padding: 2em 0;
        border-bottom: 1px solid @border-gray; 

        & > .wrapper {
            position: relative;
            display: flex;
            align-items: flex-end;
        }

        .sub-menu-block { 
            padding: 3em 4em;
            margin: 0 -5% 0 0;
        }

        .wrapper-menu {
            display: block;
            width:  100%;
        }

        .wrapper-search {
            position: absolute;
            top: 0;
            right: 0;

            input[type="search"] {
                border: none;
                font-size: 1.125em;
                height: 2em;
                width: 6em;
                float: left;
                padding: 0 1em;

                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    font-size: 0.625em;
                    font-weight: bold;
                    color: @dark-gray;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                    font-size: 0.625em;
                    font-weight: bold;
                    color: @dark-gray;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
                &:-ms-input-placeholder { /* IE 10+ */
                    font-size: 0.625em;
                    font-weight: bold;
                    color: @dark-gray;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
                &:-moz-placeholder { /* Firefox 18- */
                    font-size: 0.625em;
                    font-weight: bold;
                    color: @dark-gray;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
            }

            form#searchbox {
                display: inline-block;
                margin: 0;
                padding: 0;
            }

            input[type="button"],
            input[type="submit"] {
                background: none;
                background-image: url('../img/picto-search.svg');
                background-repeat: no-repeat;
                background-position: center bottom;
                border: none;
                width: auto;
                height: 2em;
                text-indent: -9999px;
                float: right;
            }
        }

        ul.menu {
            padding: 0; margin: 0;
            list-style: none;
            text-align: center;

            & > li {
                display: inline-block;
                padding: 0 2.5% 0 0;; 
                &.logo {
                    width: 100%;
                    vertical-align: bottom;
                    margin: 0 auto 2em;
                    text-align: center;
                }

                &.selected,
                &:hover {
                    & > a {
                        position: relative;
                        &:after {
                            position: absolute;
                            bottom: -1.9em; 
                            content: '';
                            width: 100%;
                            right: 0;
                            border-bottom: 2px solid @blue;
                            z-index: 5;
                        }
                    }

                    & > div.sub-menu-block { 
                        background-color: @white; 
                        visibility: visible;
                        opacity: 1;

                        & > * {
                            opacity: 1;
                        }
                    }
                }

                &.close {
                    & > div.sub-menu-block {
                        visibility: hidden;
                        opacity: 0;
                    }
                }


                & >  a {
                    position: relative;
                    box-sizing: border-box;
                    display: block;
                    margin: 0;
                    color: @blue;
                    cursor: pointer;
                    font-size: 1.133em;
                    font-weight: bold;

                    &.active, &:hover {
                        color: @light-blue;
                    }
                }

                /* Sub Menu */
                div.sub-menu-block { 
                    visibility: hidden;
                    background-color: @white;
                    position: absolute;
                    width: 110%;
                    margin: 0 -5%;
                    left: 0;
                    box-sizing: border-box;
                    z-index: 3;
                    opacity: 0;
                    transition: all 0.4s ease 0s;




                    .btn-close {
                        position: absolute;
                        top: 3em;
                        right: 2em;
                        left: auto;
                        z-index: 1;
                    }

                    &:before {
                        position: absolute;
                        left: 0;
                        top: 2em;
                        content: '';
                        border-top: 1px solid @border-gray;
                        width: 100%;
                        //margin: 0 -10%;
                    }

                    & > * {
                        transition-property: opacity;
                        transition-duration: 0.4s;
                        opacity: 0;
                    }

                    ul {
                        margin: .5em 0;
                        padding: 0;
                        list-style-type : none;
                        display:block;
                        text-align: left;

                        &.list-services {
                            padding: 0;
                            & > li {
                                margin: 0;
                                padding: 0;
                                line-height: 1.2;

                                & > a {
                                    font-size: 1em;
                                    font-weight: bold;
                                    color: @blue;

                                    &:hover {
                                        color:@light-blue;
                                    }
                                }
                            }
                        }

                        &.social {
                            padding: 0;
                            float: right;
                            & > li {
                                display: inline-block;
                                padding: 0 1em;
                            }
                        }
                        & > li {
                            margin: .5em 0;

                            &:first-child {
                                margin-top: 0;
                            }
                            line-height: 1.2;

                            & > a {
                                font-size: 1em;
                                font-weight: bold;
                                color: @dark-gray;

                                &:hover {
                                    color:@blue;
                                }
                            }

                            & > ul {
                                padding: 0;
                                & > li {
                                    margin: .5em 0;
                                    & > a {
                                        font-weight: normal;
                                    }
                                }
                            }
                        }
                    }
                }

                /*&:hover, .active {
                    & > div.sub-menu-block { 
                        background-color: @white; 
                        visibility: visible;
                        opacity: 1;

                        & > * {
                            opacity: 1;
                        }
                    }
                }*/
            }

        }
    }
}

@media @screenLg {
    #main-nav {
        ul.menu {
            text-align: left;
            & > li {
                &.logo {
                    width: 32%;
                    margin: 0;
                    text-align: left;
                }
            }
        }
        /*
        .sub-menu-block { 
            margin: 0 -5%;
        }*/
    }
}

/* ------------ PAGE -------------- */


#header-section {
    position: relative;
    margin-top: 6em;

    @media @screenMd {
        margin-top: 0;
    }

    p {
        color: @blue;
    }

    .wrapper-intro {
        @media @screenMd {
            position: relative;
            width: 55%;
            min-height: 19em;
        }

        .page_basic &,
        .page & {
            min-height: 14em;
        }

        header {
            margin: 4em 0 2em;
        }
        @media @screenMd {
            footer {
                position: absolute;
                bottom: auto;
            }
        }

        p {
            font-size: 1.063em;
        }

    }

    .wrapper-logo {
        width: 12rem;
        height: auto;
    }

    .list-services {
        padding: 0;
        & > li {
            margin: 0;
            padding: 0;
            line-height: 1.2;

            & > a {
                font-size: 1.063em;
                font-weight: bold;
                color: @blue;

                &:hover {
                    color:@light-blue;
                }
            }
        }
    }

    .wrapper-contact {

        img {
            padding: 1.2em 4em 0;
        }

        p {
            font-weight: bold;
            font-size: 1em;
            color: @light-blue;

            a {
                color: @blue;
            }
        }

        & + .wrapper-btn {
            margin: 3em 0 5em;
        }
    }

    .wrapper-btn {
        margin: 2em 0;
        @media @screenMd {
            margin: 5em 0;
        }
    }

    .absolute-img {
        position: relative;
        width: 60%;
        float: right;

        img {
            margin-top: -24.1em;
            width: 100%;
        }

        &.small {
            position: absolute;
            top: -4em;
            right: 0;
            width: 40%;
            z-index: 1;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100vw;
                max-width: 1680px;
                height: 1px;
                border-bottom: 1px solid @border-gray;
            }

            img {
                margin-top: 0;
                width: auto;
            }
        }

        &.logo-basic {
            position: absolute;
            top: 6.5em;
            right: 16em;
            width: 40%;
            //z-index: 1;
            img {
                margin-top: 0;
                width: auto;
            }
        }

        .wrapper-btn-discover {
            position: absolute;
            bottom: 12%;
            left: 12%;
            z-index: 5;

            img {
                margin-top: 0;
                width: auto;
            }
        }
    }
}

.section-besoins {
    .wrapper-large {
        @media @screenMd {
            margin-top: -25em;
        }
    }

    & > footer {
        padding: 1em 0;
        text-align: center;

        p {
            color: #ccc;
        }

        .wrapper-btn {
            margin: 3em 0;
        }
    }

    .absolute-img {
        position: relative;
        margin-top: 0;
        width: 46%;
        z-index: -1;
    }
}

.section-services {

    .wrapper-diagonale {
        display: flex;
        width: 100%;
        background: @dark-gray;

        .lt-ie9 & {
            display: inline-block;
        }

        &.bg-blue {
            background: @blue;
        }

        .wrapper-col-center {
            .wrapper;
            padding: 2em 0;

            @media @screenMd {
                position: relative;
                display: inline-block;
                width: 20%;
                vertical-align: top;
                z-index: 1;
                max-width: none;
                margin: 0;
                padding: 0;
            }

            @media @screenLg { 
                padding: 8em 0 5em;
            }

            header {
                @media @screenMd {
                    padding: 0 5em;
                    min-width: 35em;
                }
            }


            h1, h2, p {
                color: @white;
            }

            p {
                position: relative;
            }

            .wrapper-services-list {
                padding-bottom: 10em;
                @media @screenMd {
                    width: 250%;
                    margin: 10em -65% 0;
                    padding-bottom: 0;
                }

                .services-list {
                    & > li {
                        position: relative;
                        transition: all 0.4s ease 0s;
                        display: inline-block;
                        width: 25%;
                        padding: 0;
                        text-align: center;
                        vertical-align: top;

                        h2 {
                            margin-top: 1em;
                            transition: all 0.4s ease 0s;
                        }

                        &:hover {
                            color: @light-blue;

                            ul.list-services {

                                visibility: visible;
                                opacity: 1;
                                height: auto;

                                & > li {
                                    height: auto;
                                    display: block;
                                }
                            }
                            h2, a {
                                color: @light-blue;
                            }
                        }

                        &.inactive {
                            opacity: .5;
                        }

                        ul.list-services {
                            padding: 0 1em 1em;
                            margin: 0 auto;
                            width: 100%;
                            visibility: hidden;
                            box-sizing: border-box;
                            opacity: 0;
                            background-color: rgba(0,0,0,0.25);
                            z-index: 3;
                            transition: opacity 0.5s visibility 0.5s linear;
                            height: 0;
                            overflow: hidden;

                            &:after {
                                content: '';
                                border-bottom: 2px solid @white;
                                width: 3em;
                                height: 2px;
                                position: absolute;
                                left: 40%;
                                bottom: 0;
                                z-index: 4;
                            }

                            li {
                                height: 0;
                                display: none;
                                text-align: center;
                                overflow: hidden;
                                transition: height 1s ease;
                                
                                a {
                                    font-size: 1.063em;
                                    color: @white;

                                    &:hover {
                                        color: @light-blue;
                                    }
                                }
                            }
                        }
                    }
                }


                &.details {
                    @media @screenMd {
                        width: 200%;
                        margin: 5em -55% 0;
                        position: absolute;
                    }

                    .services-list {
                        & > li {
                            width: 49%;

                            &:nth-last-child(2) {
                                clear: both;
                            }

                            @media @screenMd {
                                width: 32%;

                                &:nth-last-child(2) {
                                    clear: none;
                                }
                            }

                            ul.list-services {
                                transition: all 0.4s ease 0s;
                                background-color: @blue;
                            }
                        }
                    }
                }

                &.details-filiales {
                    @media @screenMd {
                        width: 200%;
                        margin: 5em -65% 0;
                    }

                    .services-list {
                        & > li {
                            width: 49%;

                            ul.list-services {
                                background-color: @blue;
                            }
                        }
                    }
                }

                .wrapper-btn {
                    text-align: center;

                    .btn {
                        &.outline {
                            &:hover {
                                border-color: @white;
                                color: @white;
                            }
                        }
                    }
                }
            }

            .wrapper-content {
                @media @screenMd {
                    margin: 5em -50% 0;
                }
            }
        }

        .absolute-img {
            position: relative;
            width: 40%;
            display: inline-block;

            .lt-ie9 & {
                width: 39.7%;
            }

            &.before {
                text-align: left;
            }

            &.after {
                text-align: right;
                vertical-align: top;

                .asset-circle {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 1;
                }
            }

            &.home {
                &.before {
                }

                &.after {
                    margin-top: 6em;

                    .lt-ie9 & {
                        margin-top: 0;
                        vertical-align: bottom;
                    }
                }
            }

            .asset-logo {
                position: absolute;
                top: 0;
                right: -4em;
            }
        }
    }

    &.bottom {
        margin-bottom: 1.4%;

        .wrapper-col-center {
            @media @screenLg { 
                padding: 3em 0 5em;
            }

            & > header {
                @media @screenMd {
                    margin: 0 -100% 0;
                    padding: 0 5em 0 0;
                    min-width: auto;
                    max-width: 200%;
                }
            }
        }

        &.gradient {
            margin-bottom: 0;
            .wrapper-diagonale  {
                position: relative;
                background: linear-gradient(to bottom, rgba(245,245,245,1) 0%,rgba(255,255,255,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#00ffffff',GradientType=0 );

                .lt-ie9 & {
                    background: @white;
                }

                .wrapper-col-center {
                    position: static;
                    padding: 3em 0 0;
                    & > header {
                        /*@media @screenMd {
                            margin: 0 -50% 0;
                        }*/
                        .wrapper-img {
                            margin: 2em 0;

                            ul {
                                padding: 0;
                                margin: 0;

                                & > li {
                                    float: left;
                                    padding: 0 2.5%;
                                }
                            }
                        }

                        .wrapper-col-img {
                            clear: both;

                            @media @screenMd {
                                position: absolute;
                                top: 0;
                                right: 15%;
                                margin-top: 6em;
                                z-index: 1;
                            }

                            img {

                                margin-bottom: 1em;

                                @media @screenSm {
                                    float: left;
                                    width: 48%;
                                    padding: 0 1%;
                                }

                                @media @screenMd {
                                    display: block;
                                    float: none;
                                    width: auto;
                                    padding: 0;
                                }
                            }
                        }
                    }

                    h1, h2, p {
                        color: @blue;
                    }

                    .wrapper-group-faq {
                        @media @screenMd {
                            margin: 1em -50% 0;
                        }
                    }
                }
            }
        }
    }
}

.section-faq {
    padding: 2em 0;
}

.section-contact {
    padding: 2em 0;
    background: @contact-gray;

    .wrapper-contact {
        @media @screenMd {
            position: relative;
            z-index: 1;
            max-width: 565px;
        }
    }

    .wrapper-img {
        display: none;
        @media @screenMd {
            margin-top: -18%;
            text-align: right;
            display: block;
        }

    }
}

.section-filiales {
    padding: 2em 0;
    background: @contact-gray;

    @media @screenSm {
        .col_3 {
            width: 45%;
            float: left;
            padding: 0 2.5%;
        }
    }

    header {
        margin-bottom: 4em;
        h1 {
            color: @light-blue;
        }
    }

    .wrapper-img {
        min-height: 3.5em;
    }

    .wrapper-services-list {
        margin: 1em 0;

        & > a {
            font-weight: bold;
        }

        a {
            font-size: 0.938em;
            color: @blue;

            &:hover {
                color: @light-blue;
            }
        }
    }
}


.section-teaser-news {
    padding: 0;
    .wrapper-diagonale {
        display: flex;
        width: 100%;
        position: relative;

        h1 {
            @media @screenMd {
                margin-top: 3.6em;
            }
        }

        .wrapper-box {
            width: 100%;
        }

        .absolute-img {

            .lt-ie9 & {
                display: none;
            }

            &.after {
                position: absolute;
                right: 0;
                text-align: right;
                width: 40%;
            }
        }
    }

    article {
        margin-bottom: 1em;
    }

    & > footer {
        .wrapper;
        margin-top: 2em;
        margin-bottom: 2em;
        text-align: center;
    }
}

.wrapper-box {
    position: relative;
    z-index: 4;
    padding: 2em 0 0 0;

    @media @screenXL {
        padding: 0;
    }

    .wrapper-large {
        .section-services.bottom & {
            @media @screenXL {
                margin-top: -31em;
            }
        }

        header.header-section {
            padding: 0 0 2em;

            @media @screenMd{
                padding: 5em 2em;
            } 

            h1 {
                margin: 0;
            }
        }

        article {
            position: relative;
            background: @white;
            border: 1px solid @border-gray;
            min-height: 28.75em;
            transition: all .5s;
            margin-bottom: 1em;

            @media @screenXL {
                .section-services.bottom & {
                    border: none;
                }
            }

            &:hover {
                box-shadow: 0.5px 1px 21px 0 rgba(0,0,0,.15);

                @media @screenXL {
                    .section-services.bottom & {
                        box-shadow: 0.5px 1px 21px 0 rgba(0,0,0,.75);
                    }
                }
            }

            h2, p {
                padding: .5rem 2rem .5rem 2rem;
            }

            h2 {
                .section-teaser-news & {
                    strong {
                        display: block;
                    }
                }
            }

            p {
                font-size: 0.875em;
            }

            footer {
                position: absolute;
                bottom: 0;
                right: 0;



                .wrapper-btn {
                    text-align: right;
                }
            }

            &.big-box {
                .wrapper-img {
                    @media @screenSm {
                        display: inline-block;
                        float: left;
                        width: 54%;
                    }
                }
                .wrapper-box-content {
                    @media @screenSm {
                        display: inline-block;
                        width: 46%;
                    }
                }
            }
        }
    }
}

.section-map {
    padding: 0 0 2em;
    p {
        font-size: 1.063em;
        color: @blue;
        margin-bottom: 0;
    }

    .vcard {
        padding-bottom: 1em;

        .adr {
            margin-top: 0;
        }
        .org {
            a {
                color: @blue;
                font-weight: bold;
            }
        }

        .email {
            color: @light-blue;
            display: block;
        }

        .note {
            font-size: 0.875em;
        }
    }
}

.section-contact-form {
    padding: 0 0 2em;
    fieldset {
        padding: 0 0 2em;
    }
}

.section-partenaires {
    padding: 0 0 2em;
    background: @contact-gray;

    header {
        padding: 2em 0 2em 0;

        @media @screenMd {
            padding: 2em 0 2em 4em;
        }
    }

    article {
        header {
            padding: 3em 0 1em 0;

            @media @screenMd {
                padding: 3em 0 1em 4em;
            }
        }
    }

    #carousel-partner {

        .wrapper-img {
            text-align: center;
            margin-bottom: 1em;
        }

        /*@media @screenSm {*/
        .col_1, .col_2, .col_3, .col_4, .col_5, .col_6, .col_7, .col_8, .col_9, .col_10, .col_11, .col_12 {
            float: left!important;
            padding: 0 2.5%!important;

            .lt-ie8 & {
                padding: 0 2.4%!important;
            }
        }
        .col_3 {
            width: 45%;
        }
        /*}*/
    }

    .wrapper-customer-comment {
        padding: 1em 0;
        @media @screenMd {
            padding: 1em 6em;
        }


        background: @white;

        h2 {
            font-size: 1.500em;
            color: @blue;
        }

        .col_3 {
            position: relative;
            text-align: center;

            @media @screenMd {
                &:after {
                    position: absolute;
                    content: '';
                    top: 0;
                    right: 0;
                    border-right: 1px solid @border-gray;
                    height: 100%;
                    width: 1px;
                }
            }

            h3 {
                font-size: 0.750em;
                color: @light-blue;
                font-weight: normal;
                letter-spacing: 1px;
                margin-bottom: 0;
            }
            p {
                margin-top: .2em;
                font-size: 0.750em;
                color: #626262;
            }

            date {
                font-size: 0.625em;
                text-transform: uppercase;
                color: @blue;
            }
        }

        .col_9 {
            p {
                font-size: 0.875em;
                color: #494949;
                @media @screenMd {
                    max-width: 80%;
                }
            }
        }
        .wrapper-content {
            padding: 0 7%;
            text-align: center;

            @media @screenMd {
                text-align: left;
            }
        }
    }

    .wrapper-btn {
        margin: 2em 0 0 0;
        text-align: right;
    }
}

/* ------------ FAQ -------------- */
.wrapper-group-faq {
    padding: 1em 0 2em 0;

    .section-map & {
        padding: 1em 0 0 0;
    }

    h2 {
        color: @blue;
    }

    &.white {
        background: @white;
    }
    .wrapper-faq-item {
        background: @white;
        h3 {
            font-size: 1em;
            border: 1px solid #cfcfcf;
            font-weight: normal;
            padding: 1em 2em;
            margin: 2px 0 0.5em;
            background-image: none;
            cursor: pointer;

            .section-map & {
                color: @light-blue;
                font-weight: bold;
                margin: 2px 0;
            }

            &:before {
                content: "";
                float: right;
                width: 3em;
                height: 3em;
                margin: -1em -2em 0 0;
                background: url(../img/asset-arrow-right.svg) no-repeat center;
                transition: all .35s ease;
            }

            &.active {
                border-bottom-width: 2px;
                border-bottom-color: @light-blue;
                margin: 0;

                &:before {
                    background: url(../img/asset-arrow-right.svg) no-repeat center;
                    transform: rotate(90deg);
                }

                & + ul {
                    max-height: 500em;
                    padding: 0.25em 2em;
                    margin: 0 0 .5em;

                    .section-map & {
                        padding: 0 0 0 2em;
                    }
                }
            }
        }

        ul {
            clear: both;
            margin: 0;
            padding: 0 2em;
            transition: all .5s;
            overflow: hidden;
            list-style: none;
            transition: height .35s ease;
            height: 0;

            &.show {
                height: 10em;
                border: 1px solid #cfcfcf;
                border-bottom-width: 1px;

                .section-map & {
                    height: 33.5em;
                    @media @screenMd {
                        height: 18.5em;
                    }
                }
            }

        }
    }
}

/* ------------ OVERLAY MENU -------------- */
.overlay-menu {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    max-width: 1680px;
    background-color: rgba(0,0,0,1);
    visibility: hidden;
    opacity: 0;
    backface-visibility: hidden;
    transition: opacity .3s 0s, visibility 0s .3s;

    z-index: 10;
    @media @screenMd {
        z-index: 2;
    }

    &.visible {
        opacity: 0.6;
        visibility: visible;
        transition: opacity .3s 0s, visibility 0s 0s;
    }
}

/* ------------ ASIDE -------------- */
.shortcut-cta {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    background-color: rgba(255,255,255, 0.85);
    transition: all .5s;

    @media @screenMd {
        top: 26.5%;
        bottom: auto;
        max-width: 6em;
        width: auto;
    }

    &.inactive {
        background-color: transparent;
    }

    .btn-deploy {
        position: absolute;
        bottom: 45%;
        width: auto;
        border: none;
        left: 1.5%;
        right: auto;
        z-index: 6;

        @media @screenMd {
            position: static;
            bottom: auto;
            left: auto;
            border-bottom: 1px solid @border-gray;
            width: 70%;
            display: block;
            padding: .5em;
        }

        img {
            transition: transform 0.3s ease-in-out;
        }
    }

    ul {
        position: relative;
        margin: 1em 0;
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;

        & > li {
            text-align: center;
            line-height: 1.1;
            float: left;
            width: 25%;

            @media @screenMd {
                float: none;
                width: auto;
            }

            a {
                color: @blue;
                font-size: 0.750em;
                font-weight: bold;

                &:hover {
                    color: @light-blue;
                }
            }
        }
    }

    &.inactive {

        img {
            transform: rotate(180deg);
        }
        ul {
            transform: translateX(-150%);
        }  
    }
}

/* ------------ BREADCRUMB -------------- */


/* ------------ CAROUSEL -------------- */

.custom-navigation {
    position: absolute;
    bottom: 3em;
    width: 100%;
    z-index: 3;

    .concept & {
        bottom: 6em;
    }

    @media @screenMd {
        top: 50%;
        bottom: auto!important;
        left: 2em;
        width: auto; 

        #alert-section & {
            top: 38%;
        }
    }

    .flex-direction-nav {
        display: none;
    }

    //
    // Control paging
    //
    .flex-control-paging li {
        a {
            width: 11px;
            height: 11px;

            background: #7a6183;

            &:hover {

                background: #333;
                background: rgba(0,0,0,0.7);
            }

            &.flex-active {
                //background: @cream;
                cursor: default;
            }
        }
    }
}


/* ------------ FOOTER -------------- */

.footer {
    padding: 2em 0 .5em;
    background: @dark-gray;

    @media @screenSm {
        .col_1, .col_2, .col_3, .col_4, .col_5, .col_6, .col_7, .col_8, .col_9, .col_10, .col_11, .col_12 {
            float: left;
            padding: 0 2.5%;

            .lt-ie8 & {
                padding: 0 2.4%;
            }
        }
        .col_2,
        .col_3 {
            width: 28.33%;
        }

        .col_3 {
            &.col_contact {
                clear: both;
                article {
                    padding: 0;
                }
            }
        }
    }
    @media @screenMd {
        .col_3 {
            &.col_contact {
                clear: none;
            }
        }
    }

    article {
        @media @screenMd {
            padding: 4em 0 0 0!important;
        }

        &.services {
            padding: 0 0 4em;
        }

        h3 {
            color: @white;
        }

        p {
            font-size: 0.875em;
            color: @footer-gray;
            line-height: 1.5;

            a {
                font-size: 0.750em;
                color: @white;
                letter-spacing: 1px;
            }
        }

        ul {
            margin: 2em 0 0 0;
            padding: 0;
            list-style-type : none;
            display:block;
            text-align: left;

            & > li {
                margin: 0;
                padding: 0;
                line-height: 1.5;

                & > a {
                    font-size: 0.875em;
                    color: @footer-gray;

                    &:hover {
                        color: @white;
                    }
                }
            }

            &.list-services {
                padding: 0;
                & > li {
                    margin: 0;
                    padding: 0;
                    line-height: 1.1;

                    & > a {
                        font-size: 0.750em;
                        font-weight: bold;
                        color: @white;
                    }
                }
            }

            &.social {
                margin: 1em 0 0 0;
                padding: 0;
                & > li {
                    display: inline-block;
                    padding: 0 2em 0 0;
                }
            }
        }

        #mc_embed_signup {
            input {
                border: 1px solid @footer-gray;
                color: @white;
            }
            input[type="submit"] {
                width: 100%;
            }

            label {
                color: @footer-gray;
            }
        }
    }

    .copyright {
        p {
            font-size: 0.875em;
            color: @white;
            text-align: center;
        }
    }
}
