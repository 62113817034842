@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=39bb7921-df32-420a-8289-a0f29a184233");

    @font-face{
        font-family: "Helvetica Neue";
        src:url("../fonts/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix");
        src:url("../fonts/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix") format("eot"),url("../fonts/3dac71eb-afa7-4c80-97f0-599202772905.woff2") format("woff2"),url("../fonts/34e0e4c0-c294-49bb-9a8e-1b2cafd54e32.woff") format("woff"),url("../fonts/8a8bfee3-197c-4942-9b11-71508cc9f406.ttf") format("truetype"),url("../fonts/5101995a-e73b-4cf9-84e8-f99eb43277b1.svg#5101995a-e73b-4cf9-84e8-f99eb43277b1") format("svg");
        font-weight: normal;
    }

    @font-face{
        font-family: "Helvetica Neue";
        src:url("../fonts/b7066775-c685-4630-b98c-5da67d4981e9.eot?#iefix");
        src:url("../fonts/b7066775-c685-4630-b98c-5da67d4981e9.eot?#iefix") format("eot"),url("../fonts/dd7814bd-8abf-46c5-a4be-db0977de2cca.woff2") format("woff2"),url("../fonts/20ffa4b4-0154-4ca6-800c-468cecbd60f1.woff") format("woff"),url("../fonts/c68e9fac-92b0-47be-853c-1a0e92f68c3d.ttf") format("truetype"),url("../fonts/94d5af98-e327-4660-89da-a0c66d6fdfe9.svg#94d5af98-e327-4660-89da-a0c66d6fdfe9") format("svg");
        font-weight: bold;
    }

